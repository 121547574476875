import { mapGetters, mapActions } from 'vuex';
import { uniqueId as _uniqueId } from 'lodash-es';

export default {
  computed: {
    ...mapGetters('spedition', ['getConfigurationSet']),
    getFieldsMappings() {
      return this.getConfigurationSet().fieldsMappings;
    },
  },

  mounted() {
    if (!this.getFieldsMappings) {
      this.setFieldsMappings({
        fieldsMappings: {
          clientEntityState: '4',
          id: '',
          tenantId: '',
          mappings: this.COURIER_FIELDS,
        },
      });
    } else if (!this.getFieldsMappings.mappings.length) {
      this.getFieldsMappings.mappings = this.COURIER_FIELDS;
    }
  },
  methods: {
    ...mapActions('spedition', ['setFieldsMappings']),
    generateUid: () => _uniqueId(`nubo_${Math.random()}_`),

    getItems(destinationField) {
      return (
        this.getFieldsMappings?.mappings.filter(
          (item) => item.destinationField === destinationField && item.clientEntityState !== 2,
        ) || []
      );
    },

    getFieldsToMap(destinationField, sourceField) {
      const usedFields = this.getItems(destinationField) || [];
      return this.FIELDS.filter(
        (field) =>
          !usedFields.some((usedField) => usedField.sourceField === field.value) ||
          field.value === sourceField,
      );
    },

    onRemoveFieldMapping(item) {
      const idx = this.getFieldsMappings.mappings.indexOf(item);
      if (!item.id) {
        if (idx >= 0) {
          this.getFieldsMappings.mappings.splice(idx, 1);
        }
      } else {
        this.getFieldsMappings.mappings[idx].clientEntityState = 2;
      }
    },

    onChange(item) {
      if (!this.getFieldsMappings) return;
      const idx = this.getFieldsMappings.mappings.indexOf(item);
      if (item.id) this.getFieldsMappings.mappings[idx].clientEntityState = 3;
    },

    isAddButtonDisabled(destinationField) {
      if (!this.getFieldsMappings) return false;
      const maxNumberOfFields = this.FIELDS.length;
      return (
        this.getItems(destinationField).length === maxNumberOfFields ||
        !!this.getFieldsMappings.mappings.find(
          (field) =>
            field.destinationField === destinationField &&
            !field.sourceField &&
            field.clientEntityState !== 2,
        )
      );
    },

    onAddFieldMapping(destinationField) {
      this.getFieldsMappings.mappings.push({
        clientEntityState: '4',
        id: '',
        tenantId: '',
        sourceField: null,
        destinationField,
      });
    },
  },
};
